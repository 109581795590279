import React, { Component } from 'react';

import { getDMA, getZip } from '!app/lib/utils';

const withGeolocation = (PageComponent) => {
  class WrappingComponent extends Component {
    static async getInitialProps(context) {
      const getProps = PageComponent.getInitialProps || (async () => ({}));
      const props = await getProps(context);
      const { req } = context;
      const { geodata } = req;

      const geodataPageConfig = geodata?.geodataPageConfig ?? {};
      const dma = getDMA(req);
      const geodataOverrides = {};

      Object.keys(geodataPageConfig).forEach((key) => {
        const dmaCopyOverride = geodataPageConfig?.[key]?.[dma];
        if (dmaCopyOverride) {
          geodataOverrides[key] = dmaCopyOverride;
        }
      });
      return {
        ...props,
        geodata: { ...geodata, geodataOverrides, zip: getZip(req) },
      };
    }

    render() {
      return <PageComponent {...this.props} />;
    }
  }

  return WrappingComponent;
};

export default withGeolocation;
