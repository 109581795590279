import { ChevronDownIcon } from '@hulu/web-ui-icons';
import Text from '@hulu/web-ui/Text';
import className from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { enterKeyHandler, preventFocus } from '!app/lib/accessibilityUtils';
import { getPageType } from '!app/lib/TealiumEventsUtils';
import { normalize } from '!app/lib/utils';
import { fireUtagLink, fireUserInteraction } from '!app/metrics';
import { withUserInteraction, withUtagLink } from '!app/metrics/hoc';

const BigFooterSection = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    model: { section, items },
    asPath,
    pageType,
  } = props;
  const tealiumPageType = getPageType(asPath, pageType);

  // Handling modal open with data-target fails as the event is lost during the rerender cycle. This method
  // gets around that limitation by directly calling a hidden toggle on the modal itself to open it.
  const handleFooterItemClick = (modalId) => {
    if (modalId) {
      document
        .getElementById(modalId)
        .getElementsByClassName('modal--toggle')[0]
        .click();
    }
  };

  const onHeaderClicked = () => {
    setIsOpen(!isOpen);

    if (isOpen === false) {
      fireUserInteraction(`view_footer_section_${section}`, 'click', 'click');
      fireUtagLink({ event_name: 'view_footer_section' });
    }
  };

  const renderLinks = (links, sectionColumnKey, count) => (
    <div
      key={sectionColumnKey}
      className={`footer--row-section col-md-${12 / count} col-xs-12`}
    >
      {links.map((linkSection) => {
        const { title, link, rel, modalId } = linkSection;
        const FooterItem = withUtagLink(
          {
            event_name: 'footer_link',
            event_label: linkSection.link,
            page_type: tealiumPageType,
          },
          withUserInteraction('a', 'footer', normalize(linkSection.title))
        );

        return (
          <FooterItem
            key={`footer-${link}`}
            href={link}
            aria-label={title}
            title={title}
            rel={rel}
            onClick={modalId ? () => handleFooterItemClick(modalId) : null}
          >
            <Text variant="body14">{title}</Text>
          </FooterItem>
        );
      })}
    </div>
  );

  const footerSectionClasses = className(
    'footer-section-container',
    `col-lg-${items?.length * 2}`,
    `col-md-${items?.length > 1 ? '12 footer-section-container-single' : '4'}`,
    'col-xs-12'
  );

  return (
    <div className={footerSectionClasses}>
      <Text
        as="h3"
        variant="eyebrow12"
        className="footer--section-expand"
        aria-label={`${section} Section`}
        tabIndex="0"
      >
        {section}
      </Text>
      <Text
        as="h3"
        variant="eyebrow12"
        role="button"
        aria-label={`${section} Section`}
        tabIndex="0"
        onClick={onHeaderClicked}
        onKeyDown={enterKeyHandler(onHeaderClicked)}
        onMouseDown={preventFocus}
        className="footer--section-expand-mobile"
        aria-expanded={isOpen}
      >
        {section}
        <ChevronDownIcon
          className={`footer__icon-plus-mobile ${
            isOpen ? 'footer__icon-plus-mobile-open' : ''
          }`}
        />
      </Text>
      <div
        className={`footer-row-expandable ${
          isOpen ? 'footer-row-expanded' : 'footer-row-collapsed'
        }`}
      >
        {items?.map((item, index) => {
          const sectionColumnKey = `footer-${section}-${index}`;
          return renderLinks(item, sectionColumnKey, items.length, asPath);
        })}
      </div>
    </div>
  );
};

BigFooterSection.propTypes = {
  model: PropTypes.shape({}),
  asPath: PropTypes.string,
  pageType: PropTypes.string,
};

export default BigFooterSection;
