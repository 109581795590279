import { ServiceLogger } from '!app/lib/logger';

const logMetaData = { logName: 'LaunchDarkly' };

// LD user. In this case, our app itself is the user.
const USER = {
  key: 'hitch',
  anonymous: true,
};

/**
 * Get a feature flag state by key through the Hulu ld relay.
 * @todo add path specific targeting and custom attribute targeting
 * @param {EventEmitter} client LaunchDarkly EventEmitter client
 * @param {string} key of the feature flag
 * @return {Promise<boolean>}
 */
async function getFlagState(client, key, user = USER) {
  if (client) {
    try {
      const flagState = await client.variation(key, user, false);
      return flagState;
    } catch (error) {
      ServiceLogger.error(JSON.stringify(error), logMetaData);
    }
  }

  return false;
}

export { getFlagState };
