// This script would run at the begining of the page load(even the app.js not loaded yet), so do not expect any npm library could be used here
export const openDeeplink = ({ deeplinkUrl, isAndroid }) => {
  let dlUrl = deeplinkUrl;
  let deeplinkReloadUrl = null;
  const { location } = window;
  const INTERNAL_SERVER_ERROR = 500;
  // In android chrome we need to reload the page to show the deepview
  // To avoid deadloop, when reload the page, need to add the dl parameter, dl=false means for sure failed to launch native app
  // Here in browser side to construct the deeplinkReloadUrl and browser_fallback_url,
  // causing in the server side (express sever) could not get the original request path, etc /not-found?orig_path=
  if (isAndroid) {
    const connector = /\?/.test(location.href) ? '&' : '?';
    deeplinkReloadUrl = `${location.href}${connector}dl`;
    dlUrl = `${deeplinkUrl}S.browser_fallback_url=${deeplinkReloadUrl}=false;end`;
  }
  try {
    // here add the doc_referrer to sessionStorage to track the original referrer, after the location.assign, the referer would lost
    sessionStorage.setItem('doc_referrer', document.referrer);
  } catch (e) {
    // Nothing need to do here.
  }

  if (deeplinkReloadUrl || dlUrl.includes('shortlink')) {
    window.setTimeout(function () {
      location.assign(deeplinkReloadUrl || dlUrl);
    }, INTERNAL_SERVER_ERROR);
  } else {
    location.assign(dlUrl);
  }
};
