import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';

import { getReducerMap } from '!app/components';
import { mobileAppUrls } from '!app/config';
import { mobileDetect } from '!app/lib/environment';
import { getDisplayName, defaultGetInitialProps } from '!app/lib/hoc';

const createSingleReducer = (defaultState, actionHandlers) => {
  return (state = defaultState, action) => {
    const handler = actionHandlers[action.type];
    return handler ? handler(state, action.payload) : state;
  };
};

const createReducersMapObj = (stateToActionHandlers) => {
  return Object.keys(stateToActionHandlers).reduce((accumulated, current) => {
    const { defaultState, actionHandlers } = stateToActionHandlers[current];
    // eslint-disable-next-line
    accumulated[current] = createSingleReducer(defaultState, actionHandlers);
    return accumulated;
  }, {});
};

const createStoreFromLayout = (layout, initialState = {}) => {
  const components = get(layout, 'components', []);

  const stateToActionHandlers = components.reduce(
    (accumulatedStateToActionHandlers, component) => {
      const reducerMap = getReducerMap(component.type, component);
      if (reducerMap) {
        Object.keys(reducerMap).forEach((stateKey) => {
          const actionHandlers = reducerMap[stateKey];
          const defaultState = initialState[stateKey] || {};
          if (stateKey in accumulatedStateToActionHandlers) {
            const previousActionHandlers =
              accumulatedStateToActionHandlers[stateKey];
            // eslint-disable-next-line
            accumulatedStateToActionHandlers[stateKey] = {
              defaultState,
              actionHandlers: {
                ...previousActionHandlers.actionHandlers,
                ...actionHandlers,
              },
            };
          } else {
            // eslint-disable-next-line
            accumulatedStateToActionHandlers[stateKey] = {
              defaultState,
              actionHandlers,
            };
          }
        });
      }
      return accumulatedStateToActionHandlers;
    },
    {}
  );

  if (Object.keys(stateToActionHandlers).length > 0) {
    const reducersMapObj = createReducersMapObj(stateToActionHandlers);
    const reducers = combineReducers(reducersMapObj);

    return createStore(
      reducers,
      {},
      compose(
        applyMiddleware(thunk),
        global.window && window.__REDUX_DEVTOOLS_EXTENSION__
          ? window.__REDUX_DEVTOOLS_EXTENSION__()
          : (noop) => noop
      )
    );
  }

  return createStore((state) => state, initialState);
};

const withRedux = (PageComponent) => {
  class WrappingComponent extends Component {
    constructor(props) {
      super(props);
      const { layout, user } = props;
      const options = get(props, 'layout.options', {});
      const cartAbandonmentCopy = get(options, 'cartAbandonmentCopy', null);
      const md = mobileDetect();

      const ctaMobileFlag = get(options, 'ctaMobileFlag', false);
      let ctaMobileLink = null;

      if (ctaMobileFlag) {
        const os = md.os();
        const deviceAppUrl = mobileAppUrls[os];
        ctaMobileLink = deviceAppUrl || null;
      }

      const ctaFields = {
        ctaAnonCopy: get(options, 'ctaAnonCopy', null),
        ctaAnonLink: get(options, 'ctaAnonLink', null),
        ctaSomeCopy: get(options, 'ctaSomeCopy', null),
        ctaSomeLink: get(options, 'ctaSomeLink', null),
        ctaSomeAndNotEnrolledCopy: get(
          options,
          'ctaSomeAndNotEnrolledCopy',
          null
        ),
        ctaSomeAndNotEnrolledLink: get(
          options,
          'ctaSomeAndNotEnrolledLink',
          null
        ),
        ctaAllCopy: get(options, 'ctaAllCopy', null),
        ctaAllLink: get(options, 'ctaAllLink', null),
        ctaIneligibleCopy: get(options, 'ctaIneligibleCopy', null),
        ctaIneligibleLink: get(options, 'ctaIneligibleLink', null),
        ctaSomeNotToAddonsCopy: get(options, 'ctaSomeNotToAddonCopy', null),
        ctaSomeNotToAddonLink: get(options, 'ctaSomeNotToAddonLink', null),
        ctaInactiveCopy: get(options, 'ctaInactiveCopy', null),
        ctaInactiveLink: get(options, 'ctaInactiveLink', null),
        ctaAppleCopy: get(options, 'ctaAppleCopy', null),
        ctaAppleLink: get(options, 'ctaAppleLink', null),
        ctaMobileLink,
        cartAbandonmentCopy,
      };

      const initialState = { user, ctaFields };
      this.store = createStoreFromLayout(layout, initialState);
    }

    render() {
      if (!this.store) {
        return <PageComponent {...this.props} />;
      }
      return (
        <Provider store={this.store}>
          <PageComponent {...this.props} />
        </Provider>
      );
    }
  }

  WrappingComponent.displayName = getDisplayName('withRedux', PageComponent);
  WrappingComponent.getInitialProps = defaultGetInitialProps(PageComponent);

  WrappingComponent.propTypes = {
    layout: PropTypes.shape({}),
    user: PropTypes.shape({}),
  };

  return WrappingComponent;
};

export default withRedux;
