import AppsFlyerBanner from '@hulu/appsflyer-banner';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { getComponent } from '!app/components';
import { view as Footer } from '!app/components/Footer';
import { HOST_PROD } from '!app/lib/constants';
import { mobileDetect, isDevOrStagingEnvironment } from '!app/lib/environment';
import { OneTrustProdScript, OneTrustNonProdScript } from '!app/lib/oneTrust';
import { shouldRedirectStartPageToHome } from '!app/lib/urlUtils';
import { getPageType } from '!app/lib/utils';

const Page = (props) => {
  const {
    asPath,
    pageType,
    query,
    featureFlags,
    latestSeason,
    appsFlyerBannerKey,
    layout,
    cartAbandonment,
    geodata,
    user: { isSubscriber } = {},
    host,
  } = props;
  const { components, locale, options } = layout;
  const isMobile = mobileDetect().mobile();
  const shouldRenderAppsFlyer = isMobile && appsFlyerBannerKey;
  const contentClass = classNames('content-wrapper', {
    'content-wrapper--brand': options?.enableBrand ?? false,
  });
  const displayTheme = options?.displayTheme ?? '';
  const { hasOneTrustScriptEnabled } = featureFlags;
  const oneTrustScript = isDevOrStagingEnvironment()
    ? OneTrustNonProdScript
    : OneTrustProdScript;

  // Disable the footer on the PWA page if the unified Edna Login experience is enabled on this page.
  // The component from the @hulu/web-login-ui package will render the footer instead.
  const isOnEdnaLoginRoute =
    featureFlags?.hasEdnaLoginOnPWAEnabled && asPath.match(/\/app.*/);
  const disableFooter = isOnEdnaLoginRoute || options?.disableFooter || '';

  useEffect(() => {
    /**
     * Redirect
     */
    const redirectOut = shouldRedirectStartPageToHome({
      allowSubscriberTraffic:
        props?.layout?.options?.allowSubscriberTraffic ?? false,
      isSubscriber,
      pathname: window.location.pathname,
    });
    if (redirectOut) {
      window.location = '/';
    }

    /**
     * Onetrust execute
     */
    const oneTrustExecuteScriptElement = document.createElement('script');
    if (hasOneTrustScriptEnabled) {
      oneTrustExecuteScriptElement.async = true;
      // Executes OneTrust script client side
      oneTrustExecuteScriptElement.innerHTML = 'function OptanonWrapper() {}';
      document.body.appendChild(oneTrustExecuteScriptElement);
    }

    return () => {
      // clean up the onetrust script when the component is unmounted
      document.body.removeChild(oneTrustExecuteScriptElement);
    };
  }, [hasOneTrustScriptEnabled]);

  /**
   * Renders page components. For Welcome variations, renders specific
   * variation components.
   * @return {React.Component}
   */
  const renderComponents = () => {
    const enableBrand = options?.enableBrand ?? false;
    const contentOverrides = options?.contentOverrides;
    // Strip out query params
    const path = asPath ? asPath.split('?')[0] : null;

    return components.map((value, index) => {
      const Component = getComponent(value.type);

      if (Component) {
        return (
          <Component
            key={index}
            model={value}
            locale={locale}
            enableBrand={enableBrand}
            displayTheme={displayTheme}
            asPath={path}
            query={query}
            featureFlags={featureFlags}
            pageType={pageType}
            latestSeason={latestSeason}
            cartAbandonment={cartAbandonment}
            contentOverrides={contentOverrides}
            geodata={geodata}
            host={host}
          />
        );
      }

      return null;
    });
  };

  if (!layout || !layout.components) {
    return null;
  }

  return (
    <div
      className={classNames('page', {
        theme__dark: displayTheme === 'dark',
      })}
    >
      {shouldRenderAppsFlyer && (
        <AppsFlyerBanner bannerKey={appsFlyerBannerKey} />
      )}
      <div className={contentClass}>{renderComponents()}</div>
      {!disableFooter && (
        <Footer
          options={options}
          asPath={asPath}
          featureFlags={featureFlags}
          footerSects={layout?.bigFooter?.[0]?.sections ?? []}
          pageType={pageType}
          modals={layout?.bigFooter?.[0]?.modals ?? []}
        />
      )}
      {hasOneTrustScriptEnabled && <>{oneTrustScript}</>}
    </div>
  );
};

Page.getInitialProps = (context) => {
  const { req, query, asPath, pathname } = context;
  // Get the Page type (ex. LandingPage, BrowsePage)
  const pageType = getPageType(
    req?.layout?.options?.pageType ?? '',
    pathname.substring(1)
  );
  const appsFlyerBannerKey = req?.appsFlyer?.bannerKey;

  return {
    asPath,
    pageType,
    query,
    appsFlyerBannerKey,
    host: req?.headers?.host || HOST_PROD,
  };
};

Page.propTypes = {
  asPath: PropTypes.string,
  featureFlags: PropTypes.shape({}),
  latestSeason: PropTypes.shape({}),
  layout: PropTypes.shape({}),
  pageType: PropTypes.string,
  query: PropTypes.shape({}),
  user: PropTypes.shape({}),
  appsFlyerBannerKey: PropTypes.string,
  cartAbandonment: PropTypes.shape({}),
  host: PropTypes.string,
};

export default Page;
