import get from 'lodash/get';
import React, { Component } from 'react';

import { COOKIES } from '!app/lib/constants';
import { getCookie } from '!app/lib/cookies';
import { HocLogger } from '!app/lib/logger';
import { isHuluSubscriber } from '!app/lib/subscriptionUtils';
import { parseJSON } from '!app/lib/utils';
import { getFlagState } from '!app/services/LaunchDarkly';

const logMetaData = { logName: 'CartAbandonment' };

const withCartAbandonmentConfig = (PageComponent) => {
  class WrappingComponent extends Component {
    static async getInitialProps(context) {
      const getProps = PageComponent.getInitialProps || (async () => ({}));
      const props = await getProps(context);
      const { req } = context;
      const ldClient = get(context, 'req.ldClient');
      const hasCartAbandonmentEnabled = await getFlagState(
        ldClient,
        'cart-abandonment'
      );
      const cartAbandonmentCookie = getCookie(
        COOKIES.CART_ABANDONMENT,
        context
      );

      const cartAbandonmentCookieVals = parseJSON(
        decodeURIComponent(cartAbandonmentCookie),
        {
          logger: HocLogger,
          message: 'Unable to parse Cart Abandonment cookie. ',
          logMetaData,
        }
      );

      const { subscriber } = req;
      const isSub = isHuluSubscriber(subscriber);

      // Short circuit if the LD flag is off, we're the missing cookie, or if the user is a subscriber
      if (!hasCartAbandonmentEnabled || !cartAbandonmentCookieVals || isSub) {
        return {
          ...props,
          cartAbandonment: null,
          isSufReturningCustomer: false,
        };
      }

      const name =
        get(cartAbandonmentCookieVals, 'name', null) ||
        getCookie(COOKIES.PROFILE_NAME, context);
      const plan = get(cartAbandonmentCookieVals, 'plan', null);

      return {
        ...props,
        cartAbandonment: {
          name,
          plan,
        },
        isSufReturningCustomer: true, // used for metrics
      };
    }

    render() {
      return <PageComponent {...this.props} />;
    }
  }

  return WrappingComponent;
};

export default withCartAbandonmentConfig;
