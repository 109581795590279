import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import BigFooterSection from './BigFooterSection';

import { view as Modal } from '!app/components/Modal';
import { USER_AGENTS_REGEX } from '!app/lib/constants';
import { mobileDetect } from '!app/lib/environment';
import { FooterSocial, FooterLegal } from '!app/share/Footer';
import './BigFooterStyle.scss';

const BigFooter = (props) => {
  const { featureFlags, asPath, pageType, footerSects, modals } = props;
  const isGM = mobileDetect().match(USER_AGENTS_REGEX.GM);
  const socialFooterClasses = classNames('footer--legal-links-left', {
    hidden: isGM,
  });

  return (
    <footer
      className="cu-footer"
      role="region"
      aria-label="Footer"
      id="FooterLg"
    >
      {modals && (
        <span className="footer-modals">
          {modals.map((item) => (
            <Modal
              model={{
                id: item?.id,
                modalTitle: item?.title,
              }}
              className={item?.css_class}
              key={item?.id}
            >
              <div>
                {item?.body && (
                  <div
                    className="modal-dialog__body"
                    dangerouslySetInnerHTML={{
                      __html: item?.body,
                    }}
                  />
                )}
                {item?.footer && (
                  <div
                    className="modal-dialog__footer"
                    dangerouslySetInnerHTML={{
                      __html: item?.footer,
                    }}
                  />
                )}
              </div>
            </Modal>
          ))}
        </span>
      )}
      <div className="container-width">
        <div className="hulu-footer">
          <div className="footer--row footer--site-links">
            {footerSects.map((item, index) => (
              <BigFooterSection
                key={index}
                model={item}
                asPath={asPath}
                pageType={pageType}
              />
            ))}
          </div>
          <div className="footer--row footer--legal-links">
            <div className={socialFooterClasses}>
              {!isGM && (
                <FooterSocial
                  asPath={asPath}
                  featureFlags={featureFlags}
                  pageType={pageType}
                />
              )}
            </div>
            <div className="footer--legal-links-right">
              <FooterLegal
                asPath={asPath}
                pageType={pageType}
                featureFlags={featureFlags}
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

BigFooter.propTypes = {
  asPath: PropTypes.string,
  featureFlags: PropTypes.shape({}),
  footerSects: PropTypes.arrayOf(PropTypes.shape({})),
  pageType: PropTypes.string,
  modals: PropTypes.arrayOf(PropTypes.shape({})),
};

export default BigFooter;
