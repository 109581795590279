import * as querystring from 'querystring';
import * as nodeUrl from 'url';

import get from 'lodash/get';
import omit from 'lodash/omit';
import mobileDetect from 'mobile-detect';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

import { openDeeplink } from '../custom-scripts/deeplink';

import { isBrowser } from '!app/lib/environment';
import { getDisplayName } from '!app/lib/hoc';
import { HocLogger } from '!app/lib/logger';
import { fireDeeplinkLaunchError, fireDeeplinkLaunch } from '!app/metrics';

const logMetaData = { logName: 'DeepLink' };

export const isSupportedMobile = (mobileOS) => {
  return ['AndroidOS', 'iOS'].includes(mobileOS);
};

export const isDeeplinkSupportEntity = (mobileOS, entity) => {
  // Deep link support entity (series|movie|sports_episode|sports_team|genre|network|watch)
  // https://wwww.hulu.com/open is a generic deep link to launch native App, so also put the `open` here
  return [
    'series',
    'movie',
    'sports_episode',
    'sports_team',
    'genre',
    'network',
    'watch',
    'open',
  ].includes(entity);
};

const isHuluSubdomain = (host) => {
  if (!host) return false;
  const hostWithoutPort = host.split(':')[0];
  return (
    hostWithoutPort.endsWith('.huluqa.com') ||
    hostWithoutPort.endsWith('.hulu.com')
  );
};

export const isDeeplinkNeeded = (
  mobileOS,
  { entity, dl: paramDl, isAppsFlyer } = {},
  referer
) => {
  // check 1: only need deeplink for supported mobile devices and path
  if (
    !isSupportedMobile(mobileOS) ||
    !isDeeplinkSupportEntity(mobileOS, entity)
  )
    return false;

  // check 2: to avoid dead loop
  // for both iOS and Android, if the url has parameter dl=false, which means it failed to launch the native app
  // for Android Chrome, in any cases, it would reload with parameter dl to avoid dead loop
  // TODO: this is blocking our apps flyer link from working. Check if this param can be altered via AppsFlyer link.
  if (paramDl != null && !isAppsFlyer) return false;

  // check 3: if the referer is itself, that's means it's coming from the hulu website, then do not need deeplink.
  // The reason why not using sessionStorage is that: for android chrome, the sessionStorage works perfect, each click would open a new tab, a whole new sessionStorage.
  // But for iOS, each same url(including parameters) would reuse the same tab, which means the same sessionStorage.
  // To unify the rule, here just using the referer.
  return !isHuluSubdomain(nodeUrl.parse(referer).host);
};

export const getFallbackUrl = (urlObj, dl) => {
  const fallbackUrlObj = { ...urlObj };
  fallbackUrlObj.query = { ...urlObj.query, dl };
  return nodeUrl.format(fallbackUrlObj);
};

const getEntityId = (id) => {
  const match = id && id.match(/[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}$/i);
  return match && match[0].toLowerCase();
};

export const getDeeplink = (mobileOS, entity, entityId, query) => {
  const originalSourceId = query.content_id || query.entity_id;
  const sourceId = originalSourceId || entityId;
  if (!sourceId && entity !== 'open') return null;
  // if there is originalSourceId, which means it's source is /watch and translated by nginx to browsepage
  // set action as videos and play=true to let the native app launch playback view if possible (depends on the heimdall deeplink endpoint)
  const action =
    entity === 'watch' || Boolean(originalSourceId) ? 'videos' : 'shows';
  // for deeplink related hits tracking

  const params = {
    source: 'web_universal_deep_linking',
    play: action === 'videos',
  };

  const pathName = entity === 'open' ? '/open' : `/${action}/${sourceId}`;

  if (mobileOS === 'AndroidOS') {
    return nodeUrl.format({
      protocol: 'intent',
      hostname: '//www.hulu.com',
      pathname: pathName,
      query: { ...query, ...params },
      hash: `Intent;scheme=https;package=com.hulu.plus;`,
    });
  }
  if (mobileOS === 'iOS') {
    return nodeUrl.format({
      protocol: 'https',
      hostname: 'dl.hulu.com',
      pathname: pathName,
      query: { ...query, ...params },
    });
  }
  return null;
};

export const parseOrigPath = (origPath) => {
  if (!origPath) return {};
  const parsedObj = nodeUrl.parse(origPath);
  const queryObj = querystring.parse(parsedObj.query);
  const pathArray = get(parsedObj, 'pathname', '').split('/');
  const origEntity = pathArray[1];
  const origId =
    origEntity === 'watch' ? pathArray[2] : getEntityId(pathArray[2]);
  return { origEntity, origId, query: queryObj };
};

const withDeeplink = (PageComponent) => {
  class WrappingComponent extends React.Component {
    static async getInitialProps(context) {
      const getProps = PageComponent.getInitialProps || (async () => ({}));
      const props = await getProps(context);

      const reqHeaders = get(context, 'req.headers', {});
      let ua;
      let referer;
      if (!isBrowser()) {
        ua = get(reqHeaders, 'user-agent', '');
        referer = get(reqHeaders, 'referer', '');
      } else {
        ua = navigator.userAgent;
        referer = document.referrer;
      }

      const md = new mobileDetect(ua);
      if (md.is('bot') || md.is('mobilebot')) return props;
      const mobileOS = md.os();

      // reqQuery is the acturally query parameters of the request, while query has two more parameters {id, entity}
      const query = get(context, 'query', {});
      const reqQuery = get(context, 'req.query', {});

      const origPath = get(reqQuery, 'orig_path', '');
      const origPathObj = parseOrigPath(origPath);
      const origQuery = {
        ...omit(reqQuery, ['orig_path']),
        ...origPathObj.query,
      };

      const entity = query.entity || origPathObj.origEntity;
      const entityId = getEntityId(query.id) || origPathObj.origId;
      const isAppsFlyer = 'shortlink' in origQuery;
      props.isAppsFlyer = isAppsFlyer;

      if (
        isDeeplinkNeeded(
          mobileOS,
          { entity, dl: origQuery.dl, isAppsFlyer },
          referer
        )
      ) {
        // In Android Chrome, in the browser side to construct the reload url and fallback url
        const deeplinkUrl = getDeeplink(mobileOS, entity, entityId, origQuery);
        if (deeplinkUrl) {
          const isAndroid = mobileOS === 'AndroidOS';
          const args = { deeplinkUrl, isAndroid };
          props.deeplinkScript = `(${openDeeplink})(${JSON.stringify(args)})`;
        }
      }
      return props;
    }

    componentDidMount() {
      this.sendDeeplinkHit();
    }

    sendDeeplinkHit() {
      const { url, deeplinkScript, isAppsFlyer } = this.props;
      const query = get(url, 'query', {});
      const path = get(url, 'asPath', '');
      const dlParam = get(query, 'dl', null);
      let docReferrer = '';
      try {
        docReferrer = sessionStorage.getItem('doc_referrer');
        sessionStorage.removeItem('doc_referrer');
      } catch (err) {
        HocLogger.error(err, logMetaData);
      }
      const queryObj = {
        ...query,
        doc_referrer: docReferrer,
        isAppsFlyer,
      };
      if (dlParam === 'false' && !isAppsFlyer) {
        fireDeeplinkLaunchError(path, queryObj);
      } else if (dlParam === '' || deeplinkScript) {
        // Only fire DeeplinkLaunch hit event after the attempt of launching native app
        fireDeeplinkLaunch(path, queryObj);
      }
    }

    render() {
      // Here only when need deeplink, then inline the related scripts here
      const { deeplinkScript } = this.props;

      return (
        <div>
          <Head>
            {deeplinkScript ? (
              <script dangerouslySetInnerHTML={{ __html: deeplinkScript }} />
            ) : null}
          </Head>
          <PageComponent {...this.props} />
        </div>
      );
    }
  }

  WrappingComponent.propTypes = {
    url: PropTypes.shape({}),
    deeplinkScript: PropTypes.string,
    isAppsFlyer: PropTypes.boolean,
  };

  WrappingComponent.displayName = getDisplayName('withDeeplink', PageComponent);

  return WrappingComponent;
};

export default withDeeplink;
