import get from 'lodash/get';
import React, { Component } from 'react';

import { getFlagState } from '!app/services/LaunchDarkly';

const withFeatureFlags = (PageComponent) => {
  class WrappingComponent extends Component {
    static async getInitialProps(context) {
      const getProps = PageComponent.getInitialProps || (async () => ({}));
      const props = await getProps(context);

      try {
        // We call LD here rather than wrapping this all under the LD check
        // because the network call is more expensive than the O(n) .find/
        // Object.keys (especially because it's operating on a fixed-length
        // array).
        const ldClient = get(context, 'req.ldClient');
        const has404CarouselEnabled = await getFlagState(
          ldClient,
          '404-carousel'
        );
        const hasConnectedAuthEnabled = await getFlagState(
          ldClient,
          'connected-authentication'
        );
        const hasUnifiedLoginEnabled = await getFlagState(
          ldClient,
          'unified-identity-login'
        );
        const hasEdnaLoginOnPWAEnabled = await getFlagState(
          ldClient,
          'edna-login-pwa'
        );
        const hasEdnaLoginReviewAndAcceptEnabled = await getFlagState(
          ldClient,
          'edna-login-review-and-accept'
        );
        const hasUpdatedGenderOptions = await getFlagState(
          ldClient,
          'updated-gender-options'
        );
        const hasOneTrustScriptEnabled = await getFlagState(
          ldClient,
          'enable-one-trust-script'
        );
        const hasOneTrustFooterChangeActivated = await getFlagState(
          ldClient,
          'one-trust-privacy-update-footer-change'
        );
        const hasSubscriberAgreementUpdateEnabled = await getFlagState(
          ldClient,
          'subscriber-agreement-legal-update'
        );
        const hasHuluClientSignupAgeLimitEnabled = await getFlagState(
          ldClient,
          'hulu-client-signup-age-limit'
        );
        const featureFlags = {
          has404CarouselEnabled,
          hasConnectedAuthEnabled,
          hasUnifiedLoginEnabled,
          hasEdnaLoginOnPWAEnabled,
          hasEdnaLoginReviewAndAcceptEnabled,
          hasUpdatedGenderOptions,
          hasOneTrustScriptEnabled,
          hasOneTrustFooterChangeActivated,
          hasSubscriberAgreementUpdateEnabled,
          hasHuluClientSignupAgeLimitEnabled,
        };

        if (featureFlags) {
          return { ...props, featureFlags };
        }

        return { ...props };
      } catch (error) {
        return { ...props };
      }
    }

    render() {
      return <PageComponent {...this.props} />;
    }
  }

  return WrappingComponent;
};

export default withFeatureFlags;
