import get from 'lodash/get';
import React, { Component } from 'react';

import { isFutureDate, toPremiereDateFormatMMDDYY } from '!app/lib/utils';

const EMPTY_TOP_HAT = {
  type: 'top_hat',
  topHatId: 'lp_tophat_upcoming',
  body: '',
  metrics: {},
};

export const metatagCopy = (series, formattedDate) => {
  return {
    title: `Watch ${series} on Hulu | New Season Premieres ${formattedDate}`,
    description: `Start your free trial to catch up on ${series} before the new season premieres ${formattedDate}. Plus, enjoy thousands of other popular TV shows and movies. It's all on Hulu.`,
  };
};

export const checkHasTopHat = (components) => {
  const existingTopHat = components.find((obj) => obj.type === 'top_hat');
  return existingTopHat;
};

/**
 * Overrides title and description meta tags.
 *
 * @param {Array} metatags the primary list of metatags
 * @param {String} series the series name
 * @param {String} firstEpPremiereDate date that the new season premieres
 * @returns {Array} A new list of metatags with relevant tags overridden.
 */
const overrideMetatags = (metatags, series, firstEpPremiereDate) => {
  if (!metatags) return null;
  const tagKeys = [
    'title',
    'og:title',
    'twitter:title',
    'description',
    'og:description',
    'twitter:description',
  ];
  const formattedDate = toPremiereDateFormatMMDDYY(firstEpPremiereDate);
  const formattedMetatags = metatagCopy(series, formattedDate);

  // Create new metatag objects with overriden content
  const overriddenTags = tagKeys.map((tag) => {
    const object = metatags.find((obj) => obj.key === tag);
    const value = tag.includes('title')
      ? formattedMetatags.title
      : formattedMetatags.description;
    return {
      ...object,
      value,
    };
  });

  // Merge overridden tags with existing tags
  return metatags.map((tag) => {
    return Object.assign(
      tag,
      overriddenTags.find((newTag) => {
        return newTag && tag.key === newTag.key;
      })
    );
  });
};

/**
 * A Higher-Order-Component used to specify if a detail entity page has
 * an upcoming season.
 *
 * When you use this HOC, it will inject relevant props at the page level.
 *
 * It will also force a TopHat component to be added to the layout as long as
 * the show's latest season is considered "upcoming."
 * Existing TopHats will be overridden.
 *
 * Metatag title and description will be overridden.
 *
 * @param {*} PageComponent the page component
 * @returns {React.Component}
 */
const withUpcomingSeason = (PageComponent) => {
  class WrappingComponent extends Component {
    static async getInitialProps(context) {
      const getProps = PageComponent.getInitialProps || (async () => ({}));
      const props = await getProps(context);
      const { req } = context;
      const layout = get(req, 'layout');
      const detailEntity = get(layout, 'detailEntity');

      if (!detailEntity || !detailEntity.latestSeason) return { ...props };

      const {
        latestSeason: { firstEpPremiereDate, lastEpPremiereDate },
      } = detailEntity;

      const hasUpcomingSeason = firstEpPremiereDate
        ? isFutureDate(firstEpPremiereDate)
        : false;
      const hasLastEpPremiered = lastEpPremiereDate
        ? !isFutureDate(lastEpPremiereDate)
        : true;

      if (hasUpcomingSeason) {
        let metatags = get(layout, 'metatags', null);
        const newMetatags = overrideMetatags(
          metatags,
          detailEntity.name,
          firstEpPremiereDate
        );
        metatags = newMetatags;
      }

      const components = get(layout, 'components', null);
      const hasExistingTophat = checkHasTopHat(components);

      if (!hasExistingTophat && hasUpcomingSeason) {
        const navIndex = components.indexOf(
          components.find((obj) => obj.type === 'navigation')
        );
        components.splice(navIndex + 1, 0, EMPTY_TOP_HAT);
      }

      const latestSeason = {
        ...detailEntity.latestSeason,
        hasUpcomingSeason,
        hasLastEpPremiered,
      };
      return { ...props, latestSeason };
    }

    render() {
      return <PageComponent {...this.props} />;
    }
  }

  return WrappingComponent;
};

export default withUpcomingSeason;
