import { compose } from 'redux';

import { tealiumConf } from '../config';

import withCartAbandonmentConfig from './withCartAbandonmentConfig';
import withConfig from './withConfig';
import withDeeplink from './withDeeplink';
import withFeatureFlags from './withFeatureFlags';
import withGeolocation from './withGeolocation';
import withLayout from './withLayout';
import withMetrics from './withMetrics';
import withProfileSubscription from './withProfileSubscription';
import withRedux from './withRedux';
import withStructuredData from './withStructuredData';
import withUpcomingSeason from './withUpcomingSeason';

const hoc = compose(
  withDeeplink,
  withConfig,
  withProfileSubscription,
  withRedux,
  withStructuredData,
  withMetrics(tealiumConf),
  withCartAbandonmentConfig,
  withGeolocation,
  withLayout,
  withFeatureFlags,
  withUpcomingSeason
);

export default hoc;
