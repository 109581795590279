import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { STATUS_CODES } from '!app/lib/constants';

/**
 * A HOC to fetch the layout info from drupal and save that to initial props.
 *
 * Don't apply this over module component.
 * @param {*} PageComponent the page component
 */
const withLayout = (PageComponent) => {
  class WrappingComponent extends Component {
    static async getInitialProps(context) {
      const getProps = PageComponent.getInitialProps || (async () => ({}));
      const props = await getProps(context);

      const { res, req, asPath } = context;
      const components = get(req, 'layout.components');
      // if there is no component to show, which means no content found in hangout
      // show 404 for such page.
      // NOTE: HUWEB-5922 not reset props when getInitialProps called at client side
      if (res && (!Array.isArray(components) || components.length <= 0)) {
        res.statusCode = 404;
        return { ...props, statusCode: 404, path: asPath };
      }
      const layout = req && req.layout;
      return { ...props, layout };
    }

    render() {
      const { statusCode } = this.props;
      if (Boolean(statusCode) && statusCode !== STATUS_CODES.OK) {
        return (
          <div>
            <p>{statusCode}</p>
            <p>{statusCode.path}</p>
          </div>
        );
      }
      return <PageComponent {...this.props} />;
    }
  }

  WrappingComponent.propTypes = {
    statusCode: PropTypes.number,
    path: PropTypes.string,
  };

  return WrappingComponent;
};

export default withLayout;
