import PropTypes from 'prop-types';

import { view as BigFooter } from '!app/components/BigFooter';
import Footer from '!app/components/Footer/Footer';
import { CMS_PAGETYPE } from '!app/lib/constants';

const FooterContainer = ({
  asPath,
  featureFlags,
  options,
  pageType,
  footerSects,
  modals,
}) => {
  switch (pageType) {
    case CMS_PAGETYPE.start:
      return (
        <Footer
          asPath={asPath}
          featureFlags={featureFlags}
          pageType={pageType}
        />
      );
    case CMS_PAGETYPE.micro:
      if (options.footer === 'big') {
        return (
          <BigFooter
            asPath={asPath}
            featureFlags={featureFlags}
            footerSects={footerSects}
            pageType={pageType}
            modals={modals}
          />
        );
      }
      if (options.footer === 'small') {
        return (
          <Footer
            asPath={asPath}
            featureFlags={featureFlags}
            pageType={pageType}
          />
        );
      }
      return null;
    default:
      return (
        <BigFooter
          asPath={asPath}
          featureFlags={featureFlags}
          footerSects={footerSects}
          pageType={pageType}
          modals={modals}
        />
      );
  }
};

FooterContainer.propTypes = {
  asPath: PropTypes.string,
  featureFlags: PropTypes.shape({}),
  footerSects: PropTypes.arrayOf(PropTypes.shape({})),
  options: PropTypes.shape({}),
  pageType: PropTypes.string,
  modals: PropTypes.arrayOf(PropTypes.shape({})),
};

export default FooterContainer;
