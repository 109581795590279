import get from 'lodash/get';
import Head from 'next/head';
import { shape } from 'prop-types';
import React, { Component, Fragment } from 'react';

import { SERIES, MOVIE } from '!app/lib/constants';
import { getDisplayName } from '!app/lib/hoc';

const STRUCTURED_SERIES = 'TVSeries';
const STRUCTURED_MOVIE = 'Movie';
const IMAGE_DIMENSIONS = 'size=952x536';
const VERTICAL_IMAGE_DIMENSIONS = 'size=400x600';
const IMAGE_REGION = 'region=US';
const IMAGE_FORMAT = 'format=webp';
const DEFAULT_PRICE = 7.99;
/** @todo get availability info from heimdall */
const AVAILABILITY_START = '2014-01-01T00:00';
const AVAILABILITY_END = '2021-12-31T00:00';
const PLATFORMS = [
  'http://schema.org/DesktopWebPlatform',
  'http://schema.org/MobileWebPlatform',
  'http://schema.org/IOSPlatform',
  'http://schema.org/AndroidPlatform',
];

/**
 * Builds schema object. Needs to be stringified for browser use.
 * @param {string} type
 * @param {string} name
 * @param {string} description
 * @param {string} url
 * @param {string} image
 * @param {string} startDate
 * @param {number} price
 * @param {boolean} playable entities like movies and tv episodes
 */
const buildSchema = ({
  type,
  name,
  description,
  url,
  image,
  startDate,
  price,
  playable,
  credits,
}) => {
  // Not a constant because I just wanted to use shorthand properties
  const inLanguage = 'en';
  let action = {
    '@type': 'ViewAction',
    target: PLATFORMS.map((platform) => ({
      url,
      actionPlatform: platform,
      inLanguage,
    })),
  };
  if (playable) {
    action = {
      '@type': 'WatchAction',
      target: PLATFORMS.map((platform) => ({
        '@type': 'EntryPoint',
        urlTemplate: url,
        actionPlatform: platform,
        inLanguage,
      })),
    };
  }

  return {
    '@context': 'http://schema.org',
    '@type': type,
    name,
    description,
    url,
    image,
    releasedEvent: {
      '@type': 'PublicationEvent',
      startDate,
      location: {
        '@type': 'Country',
        name: 'US',
      },
    },
    ...credits,
    potentialAction: {
      ...action,
      expectsAcceptanceOf: [
        {
          '@type': 'Offer',
          availabilityStarts: AVAILABILITY_START,
          availabilityEnds: AVAILABILITY_END,
          category: 'subscription',
          name: 'Hulu',
          price,
          priceCurrency: 'USD',
          eligibleRegion: {
            '@type': 'Country',
            name: 'US',
          },
          seller: {
            '@type': 'Organization',
            name: 'Hulu',
            sameAs: 'https://www.hulu.com/',
          },
        },
      ],
    },
  };
};

/**
 * Adds the structured data script to a series or movie page.
 * @param {Component} PageComponent
 */
const withStructuredData = (PageComponent) => {
  class WrappingComponent extends Component {
    constructor(props) {
      super(props);

      const { layout } = props;

      const detailEntity = get(layout, 'detailEntity', null);
      const bundle = get(layout, 'bundle', null);
      const contentOverrides = layout?.options?.contentOverrides;

      this.dataType = null;
      this.schema = null;

      if (detailEntity) {
        const entityType = get(detailEntity, 'type');
        if (entityType === SERIES) this.dataType = STRUCTURED_SERIES;
        else if (entityType === MOVIE) this.dataType = STRUCTURED_MOVIE;
        if (this.dataType) {
          let dimensions = IMAGE_DIMENSIONS;
          let imagePath = get(
            detailEntity,
            'artwork.horizontalProgramTile.path'
          );
          if (entityType === MOVIE) {
            const verticalImage = get(
              detailEntity,
              'artwork.verticalProgramTile.path',
              null
            );
            if (verticalImage) {
              dimensions = VERTICAL_IMAGE_DIMENSIONS;
              imagePath = verticalImage;
            }
          }
          const image = `${imagePath}&${IMAGE_REGION}&${IMAGE_FORMAT}&${dimensions}`;
          const premiereDate = get(detailEntity, 'premiereDate') || '';
          const startDate = premiereDate.split('T')[0];
          const url = get(detailEntity, 'href');
          const price = get(bundle, 'price', DEFAULT_PRICE);
          const creditsData = detailEntity?.credits ?? [];
          // Flatten credits data so it conforms to schema.org/Movie standards for actors, directors, etc
          const credits = creditsData.reduce((creditSchema, credit) => {
            // eslint-disable-next-line no-param-reassign
            creditSchema[credit.seoSchemaProp] = credit.items.map((name) => {
              return {
                '@type': 'Person', // All are assumed to be person types
                name,
              };
            });
            return creditSchema;
          }, {});

          this.schema = JSON.stringify(
            buildSchema({
              type: this.dataType,
              name: contentOverrides?.overrideName || get(detailEntity, 'name'),
              description:
                contentOverrides?.overrideDescription ||
                get(detailEntity, 'description'),
              url,
              image,
              startDate,
              price,
              playable: entityType === MOVIE,
              credits,
            })
          );
        }
      }
    }

    static async getInitialProps(context) {
      const getProps = PageComponent.getInitialProps || (async () => ({}));
      const props = await getProps(context);

      return props;
    }

    render() {
      if (this.schema) {
        return (
          <>
            <Head>
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: this.schema }}
              />
            </Head>
            <PageComponent {...this.props} />
          </>
        );
      }
      return <PageComponent {...this.props} />;
    }
  }

  WrappingComponent.displayName = getDisplayName(
    'withStructuredData',
    PageComponent
  );

  WrappingComponent.propTypes = {
    layout: shape({}),
  };

  return WrappingComponent;
};

export default withStructuredData;
